import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { SuzukiService } from '../../services/suzuki.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  title = '';
  getRole:string;
  getName:string;
  constructor(private suzukiService: SuzukiService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.suzukiService.title.subscribe(title => {
      this.title = title;
      this.getRole = this.authService.getRole();
      this.getName = this.authService.getName();
    });

    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
         $('#sidebar').css("display", "block");
         $('#sidebar').toggleClass('active');
         if($('#uploadCont').length){
          $('#sidebar').css("position", "fixed");
          $('#sidebar').css("overflow-y", "auto");
          if ($('#sidebar').hasClass('active')) {
            // when sidebar is active, set navbar-without-sidebar class
            $('.navbar').removeClass('navbar-with-sidebar').addClass('navbar-without-sidebar');
          } else {
            // when sidebar is not active, set navbar-with-sidebar class
            $('.navbar').removeClass('navbar-without-sidebar').addClass('navbar-with-sidebar');
          }
         }
      });
  });  
}

signout() {
  this.authService.signout();
   this.router.navigate(['/login']);
 } 

 
}
