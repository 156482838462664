import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, tap, map, delay, share } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Token, ApplicationUser } from './models/token';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ResetPassword, ForgotPassword } from 'src/app/models/addUserModel';
import { Message } from 'src/app/models/usersModel';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  private loggedUser: string;
  private rememberMe: boolean;
  isLoggedIn: boolean = false;
  private role: string;
  private timer: Subscription;
  private _user = new BehaviorSubject<ApplicationUser>(null);
  user$: Observable<ApplicationUser> = this._user.asObservable();

  private currentUserSubject: BehaviorSubject<Message>;
  public currentUser: Observable<Message>;
  timerSub: Subscription;
  userSubject: BehaviorSubject<Token>;
  private readonly JwtToken = 'JwtToken';
  private readonly RefreshToken = 'RefreshToken';

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      if (event.key === 'logout-event') {
        this._user.next(null);
      }
      if (event.key === 'login-event') {
        location.reload();
      }
    }
  }

  constructor(private httpClient: HttpClient, private messageService: MessageService, private router: Router) {
    window.addEventListener('storage', this.storageEventListener.bind(this));
    this.currentUserSubject = new BehaviorSubject<Message>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Message {
    return this.currentUserSubject.value;
  }

  loginUser(user: { email: string, password: string }): Observable<boolean> {
    return this.httpClient.post<any>(`${environment.suzukiApiUrl}/authenticate`, user)
      .pipe(
        map((userLogin) => {
          this._user.next({
            email: userLogin.email,
            jwtToken: userLogin.jwtToken
          });
          this.setLocalStorage(userLogin);
          return userLogin;
        }),
        catchError(error => {
          this.messageService.add({ key: 'loginKey', severity: 'error', summary: 'Error', detail: 'Incorrect email or password' });
          return of(false);
        }));
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));

    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.router.navigate(['./login']);
  }

  isLoggedInUser() {
    return !!this.getJwtToken();
  }


  getJwtToken() {
    return localStorage.getItem("JwtToken");
  }

  doLoginUser(email: string, token: Token) {
    this.loggedUser = email;
    this.setLocalStorage(token);
    this.storeRole(token.role);
  }

  getRole() {
    return localStorage.getItem("Role");
  }

  getName() {
    return localStorage.getItem("UserName");
  }

  getUID() {
    return localStorage.getItem("uid");
  }

  //getting email for upload method
  getEmail() {
    return localStorage.getItem("email");
  }

  private storeRole(role: string) {
    localStorage.setItem(this.role, role);
  }

  signout() {
    this.clearLocalStorage();
    this._user.next(null);
    this.router.navigate(['/login']);
  }


  refreshToken(): Observable<{ jwtToken: string; refreshToken: string }> {
    const refreshToken = localStorage.getItem('RefreshToken');

    return this.httpClient.post<{ jwtToken: string; refreshToken: string }>(
      `${environment.suzukiApiUrl}/refresh-token`,
      {
        refreshToken
      }).pipe(
        tap(response => {
          localStorage.setItem('JwtToken', response.jwtToken);
          localStorage.setItem('RefreshToken', response.jwtToken);
        })
      );
  }

  private setLocalStorage(token: Token) {
    localStorage.setItem('JwtToken', token.jwtToken);
    localStorage.setItem('RefreshToken', token.jwtToken);
    localStorage.setItem('Role', token.role);
    localStorage.setItem('UserName', token.firstName + ' ' + token.lastName);
    localStorage.setItem('uid', token.id);
    localStorage.setItem('email', token.email);
    localStorage.setItem('login-event', 'login' + Math.random());
    if (this.rememberMe) {
      localStorage.setItem('rememberMe', 'yes')
    }
  }


  clearLocalStorage() {
    localStorage.removeItem('JwtToken');
    localStorage.removeItem("RefreshToken");
    localStorage.removeItem('Role');
    localStorage.removeItem('UserName');
    localStorage.removeItem('uid');
    localStorage.setItem('logout-event', 'logout' + Math.random());
  }

  private getTokenRemainingTime() {
    const accessToken = localStorage.getItem("JwtToken");
    if (!accessToken) {
      return 0;
    }
    const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    return expires.getTime() - Date.now();
  }

  private stopTokenTimer() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  requestReset(body): Observable<any> {
    return this.httpClient.post(`${environment.suzukiApiUrl}/resend-token`, body)
  }

  ForgotPassword(forgotPassword: ForgotPassword): Observable<Object> {
    return this.httpClient.post(`${environment.suzukiApiUrl}/forgot-password`, forgotPassword)
  }

  ResetPassword(resetPassword: ResetPassword): Observable<Object> {
    return this.httpClient.post(`${environment.suzukiApiUrl}/reset-password`, resetPassword);
  }

  ValidPasswordToken(body): Observable<any> {
    return this.httpClient.post(`${environment.suzukiApiUrl}/revoke-token`, body)
  }

}